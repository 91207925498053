import styled from "styled-components";
import { mobile480 } from "../responsive";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import  useAuth  from "../hooks/useAuth"
import { useEffect } from "react";
import './Register.css';
import axios from 'axios';

function RegisterService() {
    const [signed, setSigned] = useState("");
    const { signin } = useAuth();
    const navigate = useNavigate();
    let alertMessage = "";

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    
    useEffect(() => {
        if (localStorage.getItem("user_token")) {
            setSigned(true);
        }
        return;
    }, []);
    const handleLogin = (event) => {
        event.preventDefault();
        if (!email) {
            setError("Preencha com seu e-mail");
            return;
        }

        const res = signin(email);
        res.then((response) => { 
            if (response === false) {
                setError("Usuário não encontrado!");
                return;
            }
            navigate("/home");
        });
    }

    async function handleSubmit(e) {
        e.preventDefault();
        let alertMessageWrapper = document.getElementById("messageAlert");
        alertMessageWrapper.classList.add("not-show");
        let username = e.target.elements.nome.value;
        let email = e.target.elements.email.value;
        let cpf = e.target.elements.cpf.value;
        let numero = e.target.elements.numero.value;
        if(username === undefined || email  === undefined || cpf  === undefined || numero  === undefined || e.target.elements.novoDestino.checked === false && e.target.elements.mapaNumerologico.checked === false && e.target.elements.leituraTarot.checked === false && e.target.elements.almaGemea.checked === false) {
            alertMessageWrapper.classList.add("error");
            alertMessageWrapper.innerHTML = "Verifique se todos os campos foram preenchidos corretamente!";
            alertMessageWrapper.classList.remove("not-show");

            return false;
        }
        if(e.target.elements.novoDestino.checked) {
            await insertPurchase(username, e.target.elements.novoDestino.value, email, numero, cpf);
            alertMessageWrapper.classList.add("success");
            alertMessageWrapper.innerHTML = "Usuário inserido com sucesso";
        }
        if(e.target.elements.mapaNumerologico.checked) {
            await insertPurchase(username, e.target.elements.mapaNumerologico.value, email, numero, cpf);
            alertMessageWrapper.classList.add("success");
            alertMessageWrapper.innerHTML = "Usuário inserido com sucesso";
        }
        if(e.target.elements.leituraTarot.checked) {
            await insertPurchase(username, e.target.elements.leituraTarot.value, email, numero, cpf);
            alertMessageWrapper.classList.add("success");
            alertMessageWrapper.innerHTML = "Usuário inserido com sucesso";
        }
        if(e.target.elements.almaGemea.checked) {
            await insertPurchase(username, e.target.elements.almaGemea.value, email, numero, cpf);
            alertMessageWrapper.classList.add("success");
            alertMessageWrapper.innerHTML = "Usuário inserido com sucesso";
        }
        alertMessageWrapper.classList.remove("not-show");
    }

    async function insertPurchase(username, userproduct, useremail, usernumber, userdocument) {
        axios.post("https://newapi.vidamistica.com/perfectpay/purchase", 
        {
            "token": "string",
            "code": "string",
            "sale_amount": 0,
            "currency_enum": 0,
            "coupon_code": "string",
            "installments": 0,
            "installment_amount": 0,
            "shipping_type_enum": "string",
            "shipping_amount": 0,
            "payment_method_enum": 0,
            "payment_type_enum": 0,
            "billet_url": "string",
            "billet_number": "string",
            "billet_expiration": "2023-02-13T15:30:27.127Z",
            "quantity": 0,
            "sale_status_enum": 0,
            "sale_status_detail": "string",
            "date_created": "2023-02-13T15:30:27.127Z",
            "date_approved": "2023-02-13T15:30:27.127Z",
            "tracking": "string",
            "url_tracking": "string",
            "checkout_type_enum": "string",
            "product": {
              "code": userproduct
            },
            "plan": {
              "name": "Inserção manual",
              "code": userproduct
            },
            "plan_itens": [
              "string"
            ],
            "customer": {
              "full_name": username,
              "email": useremail,
              "phone_area_code": usernumber.slice(0, 2),
              "phone_number": usernumber.slice(2),
              "identification_number": userdocument,
              "street_name": "",
              "city": "",
              "complement": "",
              "district": "",
              "street_number": "",
              "state": "",
              "country": "",
              "zip_code": "",
              "birthday": ""
            },
            "metadata": {},
            "webhook_owner": "string",
            "commission": "string",
            "url_send_webhook_pay": "string"
          }).then((response) => {
            console.log(response);
        });
    }

    return (
        <>
            <div class="container">
                <form class="form" onSubmit={handleSubmit}>
                    <div class="not-show" id="messageAlert">
                        <p>{ alertMessage }</p>
                    </div>
                    <div class="titleDiv">
                        <h1>Cadastro de Cliente</h1>
                    </div>
                    <div class="inputDiv">
                        <label for="nome">Nome do cliente</label>
                        <input name="nome" id="nome" type="text" placeholder="Exemplo da Silva" />
                    </div>
                    <div class="inputDiv">
                        <label for="email">Email do cliente</label>
                        <input name="email" id="email" type="text" placeholder="exemplo@exemplo.com" />
                    </div>
                    <div class="inputDiv">
                        <label for="cpf">CPF do cliente</label>
                        <input name="cpf" id="cpf" type="text" pattern="(\d{3}\.?\d{3}\.?\d{3}-?\d{2})|(\d{2}\.?\d{3}\.?\d{3}/?\d{4}-?\d{2})" placeholder="123.456.789-01" />
                    </div>
                    <div class="inputDiv">
                        <label for="numero">Número do cliente</label>
                        <input name="numero" id="numero" type="tel" placeholder="(12) 34567-8901" />
                    </div>
                    <div class="titleDiv">
                        <h1>Produtos adquiridos</h1>
                    </div>
                    <div class="checkboxDiv">
                        <input type="checkbox" name="products[]" id="novoDestino" value="PPPB3S4L" />
                        <p>Novo Destino</p>
                    </div>
                    <div class="checkboxDiv">
                        <input type="checkbox" name="products[]" id="mapaNumerologico" value="PPPB3S4O" />
                        <p>Mapa Numerológico</p>
                    </div>
                    <div class="checkboxDiv">
                        <input type="checkbox" name="products[]" id="leituraTarot" value="PPPB3S4T" />
                        <p>Leitura de Tarô</p>
                    </div>
                    <div class="checkboxDiv">
                        <input type="checkbox" name="products[]" id="almaGemea" value="PPPB3S4S" />
                        <p>Retrato da Alma Gêmea</p>
                    </div>
                    <div class="button">
                        <button type="submit">Cadastrar</button>
                    </div>
                </form>   
            </div>
        </>
    );
}

export default RegisterService
