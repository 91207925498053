import styled from "styled-components";
import { mobile480, mobile360, mobile768 } from "../responsive";
import { useState, useRef } from "react";
import PopupForm from "../components/Popup";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import  useAuth  from "../hooks/useAuth"
import { Navigation, Pagination, Scrollbar, A11y, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './home.css';

const Container = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
    width: 100vw;
    justify-content: center;
`;

const CenteredContainer = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 12px;
`;

const Wrapper = styled.div`
    width: 40%;
    height: 100%;
    padding: 15px;
    margin: 40px 0px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    border-radius: 8px;

    ${mobile480({ width: "75%", fontSize: "18px", })}
`;

const Top = styled.div`
    display: flex;
    flex-direction: column;
`;

const Logo = styled.img`
    width: 60px;
    height: 60px;
`;

const LogoWrapper = styled.div`
    text-align: center;
`;

const Button = styled.button`
    margin: 5px 0px;
    padding: 5px;
    background-color: rgba(255, 105, 180, 0.5);
    border: 1px solid #ffffff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease-in;
    cursor: pointer;
    color: #ffffff;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background-color: rgba(255, 105, 180, 0.8);
    }

    ${({active}) => active && `
        background-color: rgba(255, 105, 180, 0.8);
    `}
    
    ${mobile768({ fontSize: "13px", })};
    ${mobile480({ fontSize: "14px", })};
    ${mobile360({ fontSize: "12px", })};   
`;

const ButtonInactive = styled.button`
    margin: 5px 0px;
    padding: 5px;
    background-color: rgba(201, 201, 201, 0.5);
    border: 1px solid #ffffff;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease-in;
    cursor: pointer;
    color: #ffffff;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background-color: rgba(90, 90, 90, 0.8);
    }

    ${({active}) => active && `
        background-color: rgba(90, 90, 90, 0.8);
    `}

    ${mobile768({ fontSize: "13px", })};
    ${mobile480({ fontSize: "14px", })};
    ${mobile360({ fontSize: "12px", })};  
`;

const Desc = styled.p`
    margin: 10px 0px;
    ${mobile768({ fontSize: "17px", })};
`;

const DescBold = styled.p`
    margin: 10px 0px;
    font-weight: bold;
`;

const Content = styled.div`
    margin-top: 14px;
    overflow: hidden;
    white-space: nowrap;
`;

const SelectedDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

const Title = styled.h2`
    text-align: center;
    font-weight: 500;
    letter-spacing: 1px;

    ${mobile768({ fontSize: "19px", })};
`;

const Footer = styled.div`
    margin-top: 20px;
`;

const Info = styled.p`
    font-size: 14px;
`;

const LinkButton = styled.a`
    text-decoration: none;
    color: #fff;
    background-color: rgba(115, 255, 105, 0.5);
    padding: 4px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid #fff;
`;

const SoulmateImage = styled.img`
    width: 60%;
    height: 60%;
    margin-top: 20px;
`;

const CoverImg = styled.img`
    width: 100%;
`;

const Types = [{product_name: '🔮 Novo Destino🌛', status: 'inactive', product: 'Novo Destino', generated: false, cover: '../images/covers/cover-1.jpg', inactiveCover: '../images/covers/cover-inactive-1.jpg'}, {product_name: '🔢 Mapa Numerológico 🌀', status: 'inactive', product: 'Numerologia', generated: false, cover: "../images/covers/cover-2.jpg", inactiveCover: '../images/covers/cover-inactive-2.jpg'}, {product_name: '🃏 Leitura de Tarô 🎴', status: 'inactive', product: 'Tarot', generated: false, cover: "../images/covers/cover-3.jpg", inactiveCover: '../images/covers/cover-inactive-3.jpg'}, {product_name: '📝 Conheça sua Alma Gêmea 👩‍❤️‍💋‍👨', status: 'inactive', product: 'Desenho da Alma Gêmea', generated: false, cover: "../images/covers/cover-4.jpg", inactiveCover: '../images/covers/cover-inactive-4.jpg'}, {product_name: '💫 Orientações Semanais 📆', status: 'active', product: 'Orientações semanais', generated: false, cover: "../images/covers/cover-5.jpg"}, {product_name: '🎁 Manifestação de Midas 🔈', status: 'active', product: 'Manifestação de Midas', generated: false, cover: "../images/covers/cover-6.jpg"}, {product_name: '👩‍💻 Consulta Individual com Cigana 💃', status: 'active', product: 'Consulta Individual com Cigana', generated: false, cover: "../images/covers/cover-7.jpg"}];
function load_products() {
    var user_products = JSON.parse(localStorage.getItem("user_products"));
    if(user_products){
        user_products.map(product => {
            switch(product.product_id) {
                case 1:
                    Types[0].status = 'active';
                    Types[0].product_name = '🔮 Novo Destino🌛';
                    if(product.generated === true) {
                        Types[0].generated = true;
                    } else {
                        Types[0].generated = false;
                    }
                break;

                case 2:
                    Types[1].status = 'active';
                    Types[1].product_name = '🔢 Mapa Numerológico 🌀';
                    if(product.generated === true) {
                        Types[1].generated = true;
                    } else {
                        Types[1].generated = false;
                    }
                break;

                case 3:
                    Types[2].status = 'active';
                    Types[2].product_name = '🃏 Leitura de Tarô 🎴';
                    if(product.generated === true) {
                        Types[2].generated = true;
                    } else {
                        Types[2].generated = false;
                    }
                break;
            
                case 4:
                    Types[3].status = 'active';
                    if(product.generated === true) {
                        Types[3].generated = true;
                    } else {
                        Types[3].generated = false;
                    }
                break;

                case 5:
                    Types[4].status = 'active';
                break;

                default:
                    return false;
            }
            return true;
        });
    }
}

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
function simulateMouseClick(element){
  mouseClickEvents.forEach(mouseEventType =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: false,
          buttons: 1
      })
    )
  );
}

var div_text = "";
function ToggleGroup() {
    const ref = useRef(null);
    const userData = JSON.parse(localStorage.getItem('user_token'));
    const weekOrientation = localStorage.getItem('week_orientation');
    const soulmateImage = localStorage.getItem('soulmate_image');
    const numerologyProduct = localStorage.getItem('numerology_path');
    const astrologyProduct = localStorage.getItem('astrology_path');
    const tarotProduct = localStorage.getItem('tarot_path');
    const soulmateDeadline = localStorage.getItem('soulmate_deadline');
    const tarotDeadline = localStorage.getItem('tarot_deadline');
    var soulmateTimeout;
    var tarotTimeout;
    var getMonday = (d) => {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:1);
        return new Date(d.setDate(diff));
    }
    var getWeek = (d) => {
        d = new Date(d);
        var initial_day = d.getDay();
        var final_day = d.getDate() - initial_day + 7

        return new Date(d.setDate(final_day));
    }
    var monday_day = getMonday(new Date());
    var sunday_day = getWeek(getMonday(new Date()));
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);    
    const getTime = () => {
        const soulmateDeadline = localStorage.getItem('soulmate_deadline');
        const time = Date.parse(soulmateDeadline) - Date.now();
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    }
    if((new Date(soulmateDeadline).getTime() - new Date().getTime()) > 0) {
        if(soulmateTimeout){
            clearTimeout(soulmateTimeout);
        }
        soulmateTimeout = setTimeout(() => {
            getTime();
        }, 1000);
    }

    const [hoursTarot, setHoursTarot] = useState(0);
    const [minutesTarot, setMinutesTarot] = useState(0);
    const [secondsTarot, setSecondsTarot] = useState(0);   
    const getTimeTarot = () => {
        const tarotDeadline = localStorage.getItem('tarot_deadline');
        const time = Date.parse(tarotDeadline) - Date.now();
        setHoursTarot(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutesTarot(Math.floor((time / 1000 / 60) % 60));
        setSecondsTarot(Math.floor((time / 1000) % 60));
    }
    if((new Date(tarotDeadline).getTime() - new Date().getTime()) > 0) {
        if(tarotTimeout){
            clearTimeout(tarotTimeout);
        }
        tarotTimeout = setTimeout(() => {
            getTimeTarot();
        }, 1000);
    }
    const { signin } = useAuth();
    const handleClick = () => {
        const res = signin(userData.email);
        load_products();
      };
    const [active, setActive] = useState(Types[0]);
    const [status, setStatus] = useState();
    const [generated, setGenerated] = useState();
    switch(active) {
        case 'Novo Destino':
            if (status === 'active') { 
                if(generated) {
                    div_text = <SelectedDiv id="content_div" ref={ref}>
                        <Title>🔮 Novo Destino 🌛</Title>
                        <Desc>Entenda profundamente a sua vida através desse relatório personalizado!</Desc>
                        <LinkButton href={"https://astrolovers-assets.s3.sa-east-1.amazonaws.com/astrology/" + astrologyProduct} target={'_blank'}>Acessar seu PDF personalizado</LinkButton>
                    </SelectedDiv>;
                } else {
                    div_text = <SelectedDiv id="content_div" ref={ref}>
                        <Title>🔮 Novo Destino 🌛</Title>
                        <Desc>Você está há um passo de conhecer profundamente sua personalidade e tudo o que a Astrologia tem a revelar sobre sua vida, passado, presente e futuro!</Desc>
                        <PopupForm product={'novodestino'} setActive={setActive} setGenerated={setGenerated}/>
                    </SelectedDiv>;
                }
            } else {
                div_text = <SelectedDiv ref={ref}>
                    <Title>🔮 Novo Destino 🌛</Title>
                    <Desc style={{'textDecoration': 'underline', 'textAlign': 'center', }}><i>Você ainda não desbloqueou esse produto</i> 🔒</Desc>
                    <Desc>Faça agora mesmo sua Leitura Astrológica Personalizada Gratuita e tenha em mãos um relatório poderoso para usar durante toda a vida.</Desc>
                    <Desc>O Novo Destino é um conjunto de orientações e previsões personalizadas para cada pessoa criadas a partir de seu Mapa Astral. Essa leitura personalizada vai ajudar você não só a se conhecer melhor, mas também a usar como vantagem seus pontos positivos interpretados através da Astrologia.</Desc>
                    <LinkButton href="https://destino.souastrolovers.com" target={'_blank'}>Leitura Astrológica Gratuita</LinkButton>
                </SelectedDiv>;
            }
        break;

        case 'Numerologia':
            if (status === 'active') { 
                if(generated) {
                    div_text = <SelectedDiv id="content_div" ref={ref}>
                        <Title>🔢 Mapa Numerológico 🌀</Title>
                        <Desc>Entenda profundamente a sua vida através desse relatório personalizado!</Desc>
                        <LinkButton href={"https://astrolovers-assets.s3.sa-east-1.amazonaws.com/" + numerologyProduct} target={'_blank'}>Acessar Leitura Numerológica</LinkButton>
                    </SelectedDiv>;
                } else {
                    div_text = <SelectedDiv id="content_div" ref={ref}>
                        <Title>🔢 Mapa Numerológico 🌀</Title>
                        <Desc>Você está há um passo de conhecer profundamente sua personalidade e tudo o que a numerologia tem a revelar sobre sua vida, passado, presente e futuro!</Desc>
                        <PopupForm product={'numerology'} setActive={setActive} setGenerated={setGenerated}/>
                    </SelectedDiv>;
                }
            } else {
                div_text = <SelectedDiv id="content_div" ref={ref}>
                    <Title>🔢 Mapa Numerológico 🌀</Title>
                    <Desc style={{'textDecoration': 'underline', 'textAlign': 'center', }}><i>Você ainda não desbloqueou esse produto</i> 🔒</Desc>
                    <Desc>Descubra o que sua Numerologia Pessoal revela sobre sua vida através desse Teste Numerológico Gratuito. </Desc>
                    <Desc>O Mapa Numerológico fornece uma análise personalizada e individual baseada em sua data de nascimento e nome, esse mapa revela quais são os números da sua vida e o destino que cada um deles reserva para você.</Desc>
                    <LinkButton href="https://numerologia.vidamistica.com" target={'_blank'}>Teste Numerológico Gratuito</LinkButton>
                </SelectedDiv>;
            }
        break;

        case 'Tarot':
            if (status === 'active') { 
                if(generated) {
                    if((new Date(soulmateDeadline).getTime() - new Date().getTime()) < 0) {
                        div_text = <SelectedDiv id="content_div" ref={ref}>
                            <Title>🃏 Leitura de Tarô 🎴</Title>
                            <Desc>Entenda profundamente a sua vida através desse relatório personalizado!</Desc>
                            <LinkButton href={"https://astrolovers-assets.s3.sa-east-1.amazonaws.com/tarot/" + tarotProduct} target={'_blank'}>Acessar Leitura de Tarot</LinkButton>
                        </SelectedDiv>;
                    } else {
                        div_text = <SelectedDiv id="content_div" ref={ref}>
                            <Title>🃏 Leitura de Tarô 🎴</Title>
                            <Desc>Por favor, aguarde até sua leitura estar pronta...</Desc>
                            <Desc style={{'fontSize': '22px', 'textAlign': 'center', }}>{hoursTarot < 10 ? "0" + hoursTarot : hoursTarot}:{minutesTarot < 10 ? "0" + minutesTarot : minutesTarot}:{secondsTarot < 10 ? "0" + secondsTarot : secondsTarot}</Desc>
                        </SelectedDiv>;
                    }
                } else {
                    div_text = <SelectedDiv id="content_div" ref={ref}>
                        <Title>🃏 Leitura de Tarô 🎴</Title>
                        <Desc>Você está há um passo de conhecer profundamente sua personalidade e tudo o que a tarologia tem a revelar sobre sua vida, passado, presente e futuro!</Desc>
                        <PopupForm product={'tarot'} setActive={setActive} setGenerated={setGenerated} />
                    </SelectedDiv>;
                }
            } else {
                div_text = <SelectedDiv id="content_div" ref={ref}>
                    <Title>🃏 Leitura de Tarot 🎴</Title>
                    <Desc style={{'textDecoration': 'underline', 'textAlign': 'center', }}><i>Você ainda não desbloqueou esse produto</i> 🔒</Desc>
                    <Desc>Receba uma interpretação personalizada e gratuita do que as Cartas revelam para sua vida e seu futuro.</Desc>
                    <Desc>A Leitura de Tarot  vai te dar uma visão única sobre o seu futuro e permite você enfrentá-lo com sabedoria e inteligência.</Desc>
                    <LinkButton href="https://tarot.vidamistica.com" target={'_blank'}>Leitura Gratuita de Tarot</LinkButton>
                </SelectedDiv>;
            }
        break;

        case 'Desenho da Alma Gêmea':
            if (status === 'active') { 
                if(generated) {
                    if((new Date(soulmateDeadline).getTime() - new Date().getTime()) < 0) {
                        div_text = <SelectedDiv id="content_div" ref={ref}>
                            <Title>📝 Conheça sua Alma Gêmea 👩‍❤️‍💋‍👨</Title>
                            <CenteredContainer>
                                <SoulmateImage src={require('../assets/images/soulmate_drawings/' + soulmateImage)}></SoulmateImage>
                            </CenteredContainer>
                        </SelectedDiv>;
                    } else {
                        div_text = <SelectedDiv id="content_div" ref={ref}>
                            <Title>📝 Conheça sua Alma Gêmea 👩‍❤️‍💋‍👨</Title>
                            <Desc>Por favor, aguarde até o desenho da sua alma gêmea estar pronto...</Desc>
                            <Desc style={{'fontSize': '22px', 'textAlign': 'center', }}>{hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}</Desc>
                        </SelectedDiv>;
                    }
                } else {
                    div_text = <SelectedDiv id="content_div" ref={ref}>
                        <Title>📝 Conheça sua Alma Gêmea 👩‍❤️‍💋‍👨</Title>
                        <Desc>Você está a um passo de descobrir o retrato da sua alma gêmea! Preencha as informações necessárias com atenção, pois esse processo só pode ser feito uma vez!</Desc>
                        <PopupForm product={'soulmate'} setActive={setActive} setGenerated={setGenerated} getTime={getTime}/>
                    </SelectedDiv>; 
                }
            } else {
                div_text = <SelectedDiv ref={ref}>
                    <Title>📝 Desenho da Alma Gêmea 👩‍❤️‍💋‍👨</Title>
                    <Desc style={{'textDecoration': 'underline', 'textAlign': 'center', }}><i>Você ainda não desbloqueou esse produto</i> 🔒</Desc>
                    <Desc>Tenha em mãos o desenho da sua alma gêmea criado a partir de uma visão psíquica.</Desc>
                    <Desc>O retrato da Alma Gêmea é um serviço único e exclusivo, fruto de uma parceria Internacional feita com um famoso vidente Chinês onde desenhos preditivos de almas gêmeas são feitos através de sua clarividência.</Desc>
                    <LinkButton href="https://love.vidamistica.com" target={'_blank'}>Conheça sua Alma Gêmea</LinkButton>
                </SelectedDiv>;
            }
        break;

        case 'Orientações semanais':
            if(weekOrientation) {
                div_text = <SelectedDiv ref={ref}>
                    <Title>💫 Orientação Semanal 📆</Title>
                    <Desc>Sua orientação da semana do dia {monday_day.getDate() < 10 ? "0" + monday_day.getDate() : monday_day.getDate()}/{monday_day.getMonth()+1 < 10 ? "0" + (monday_day.getMonth()+1) : monday_day.getMonth()+1} até o dia {sunday_day.getDate() < 10 ? "0" + sunday_day.getDate() : sunday_day.getDate()}/{sunday_day.getMonth()+1 < 10 ? "0" + (sunday_day.getMonth()+1) : sunday_day.getMonth()+1}</Desc>
                    <Desc>Essa é a sua orientação da semana. Lembre-se de lê-la todos os dias e colocar em prática os conselhos aqui citados!</Desc>
                    <LinkButton href={weekOrientation} target={'_blank'}>Acessar sua Orientação Semanal</LinkButton>
                </SelectedDiv>;
            } else {
                div_text = <SelectedDiv ref={ref}>
                    <Title>💫 Orientações Semanais 📆</Title>
                    <Desc>Receba toda semana uma previsão feita para você e escrita à mão baseada em Astrologia para todas as áreas da sua vida.</Desc>
                    <Desc>Obs: A orientação semanal fica disponível aqui na área de membros e também é enviada para por e-mail todas as segundas-feiras!</Desc>
                </SelectedDiv>;
            }
        break;

        case 'Manifestação de Midas':
            div_text = <SelectedDiv>
                <Title>🎁 Manifestação de Midas 🔈</Title>
                <Desc>Com esse bônus, você vai ter aprender como atrair a abundância e manifestar riqueza em todas as áreas da sua vida usando frequências poderosas para aplicar a Lei da Atração na sua vida de forma prática e simples. Descubra como amplificar e fortalecer seus chakras para o que o universo reserva para você.</Desc>
                <LinkButton href="https://souastrolovers.com/toque-de-midas/" target={'_blank'}>Acessar Bônus Agora</LinkButton>
            </SelectedDiv>
        break;
        
        case 'Consulta Individual com Cigana':
            div_text = <SelectedDiv ref={ref}>
                <Title>👩‍💻 Consulta Individual com Cigana 💃</Title>
                <Desc>Agende seu horário e faça uma sessão através de vídeo com a Cigana e tire todas as suas dúvidas sobre seu momento de vida e seu futuro.</Desc>
                <LinkButton href="https://souastrolovers.com/cigana-site/" target={'_blank'}>Saiba Mais</LinkButton>
            </SelectedDiv>;
        break;

        default:
            div_text = "";
        break;
    }
    return ( 
        <>
            <Content>
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    modules={[Navigation, Pagination, Scrollbar, A11y, EffectCoverflow]}
                    spaceBetween={8}
                    slidesPerView={2}
                    centeredSlides={true}
                    loop={true}
                    slideToClickedSlide={true}
                    onTransitionEnd={() => document.querySelector('.swiper-slide-active') !== null ? simulateMouseClick(document.querySelector('.swiper-slide-active')) : ""}
                >
                {Types.map(Type => {
                    if(Type.status === 'active') {
                        return (<SwiperSlide onClick={() => { setActive(Type.product); setStatus(Type.status); setGenerated(Type.generated); handleClick();} }>
                            <CoverImg active={active === Type.product} src={Type.cover}/>
                        </SwiperSlide>);
                    }
                    else {
                        return (
                        <SwiperSlide onClick={() => { setActive(Type.product); setStatus(Type.status); setGenerated(Type.generated); handleClick();} }>
                            <CoverImg active={active === Type.product} onClick={() => { setActive(Type.product); setStatus(Type.status); setGenerated(Type.generated); handleClick();} } src={Type.inactiveCover}/>
                        </SwiperSlide>);
                    }
                })}
                </Swiper>
            </Content>
            { div_text }
        </>
    )
}

const Home = () => {
    const [signed, setSigned] = useState("");
    useEffect(() => {
        load_products();
        if (localStorage.getItem("user_token")) {
            setSigned(true);
        }
        return;
    }, []);
    if (!signed) {
        return <Navigate to="/home" />;
    }

    return (
        <Container>
            <Wrapper>
                <Top>
                    <LogoWrapper>
                        <Logo src={require("../assets/images/logo.png")} />
                    </LogoWrapper>
                    <ToggleGroup />
                </Top>
            </Wrapper>
        </Container>
    )
}

export default Home
